.animation-modal-youtube-player {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 15px;
}

.animation-modal-content {
    width: 60vw;
    max-width: 900px;
}

.animation-modal-soundtrack-link {
    text-align: center;
}

@media (max-width: 900px) {
    .animation-modal-content {
        width: auto;
    }
}

@media (max-height: 760px) {
    .animation-modal-youtube-player {
        height: auto;
        max-height: 80vh;
        aspect-ratio: 16 / 9;
    }
}