/* Navbar and Background */
.full-navbar {
    /* background-color: #282c34; */
    z-index: 1001;
    background-color: #253593df;
    border-radius: 15px;
    padding: 7px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Navbar Layout */
.navbar {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    z-index: 1;
}

/* Logo Styling */
.logo {
    color: white;
    text-decoration: none;
    font-family: 'Sofa', sans-serif;
    font-size: 1em;
    padding: 0;
    margin: 0;
    user-select: none;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: auto;
}

.bar {
    height: 3px;
    width: 25px;
    background-color: white;
    margin: 3px 0;
    transition: 0.3s;
}

.nav-links {
    width: 100%;
}

.nav-links ul {
    font-size: 0.85em;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links li a {
    color: white;
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
    letter-spacing: 1px;
}

.nav-links li a:hover {
    background-color: rgb(86, 124, 206);
}

.nav-links li a:active {
    background-color: white;
}

/* Mobile and Tablet Styles */
@media (max-width: 930px) {
    .menu-toggle {
        display: flex;
    }

    .nav-links {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background-color: #253593df;
        align-items: center;
        transform: translateY(calc(-100%));
        transition: transform 0.3s ease-in-out;
        padding: 10px 0;
        z-index: 1;
        border-radius: 15px;
        position: fixed;
    }

    .nav-links.open {
        transform: translateY(0);
    }

    .navbar {
        position: absolute;
    }

    .nav-links ul {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .nav-links li {
        margin: 10px 0;
    }

    .full-navbar {
        top: 0;
        left: 0;
        /* position: fixed; */
        width: 100vw;
        margin-top: 10px;

    }

    .menu-toggle {
        position: fixed;
        top: 32px;
        right: 32px;
    }
}