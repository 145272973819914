.freebies-container section {
    margin-bottom: 30px;
}

.music-pack-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 15px 30px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: transparent;
}

.thumbnail-container {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.thumbnail {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.thumbnail-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
}

.pack-title {
    padding: 0;
}

.pack-info {
    flex-grow: 1;
    margin: 15px;
}

.download-button {
    padding: 10px 15px;
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #0056b3;
}

.free-tracks,
.paid-tracks {
    margin-top: 20px;
}

.freebies-guidelines-list {
    margin-left: 3em;
    font-style: italic;
}

@media (max-width: 660px) {
    .thumbnail-container {
        display: none;
    }

    .pack-description {
        display: none;
    }

    .freebies-container {
        text-align: center;
    }

    .freebies-guidelines-list {
        text-align: justify;
        margin: 5px;
    }
}

@media (max-width: 450px) {
    .download-button {
        display: none;
    }
}