.youtubePlayer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.youtubePlayer::before {
    content: '';
    display: block;
    padding-top: 56.25%;
}

.youtubePlayer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.welcome-message {
    display: block;
    text-align: justify;
}

.home-container>div:not(:last-child) {
    margin-bottom: 40px;
}

.bluesky-feed-content {
    padding: 0 30px;
}