.music-modal-player-embed {
    border: none;
    width: 100%;
    height: 100%;
    min-height: 400px;
    border-radius: 15px;
    background-color: transparent;
}

.music-modal-content {
    max-width: 800px;
}

.music-modal-description {
    text-align: justify;
    margin: 15px 0;
}

.music-modal-player-image {
    width: 35%;
    height: auto;
    border-radius: 15px;
    cursor: default;
}

@media (max-height: 630px) {
    .music-modal-player-embed {
        min-height: 100%;
    }
}