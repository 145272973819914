.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.artwork-modal-image {
    max-width: 100%;
    height: auto;
    max-height: 70vh;
    object-fit: contain;
    border-radius: 15px;
    cursor: default;
    margin-bottom: 10px;
}

.artwork-arrow-button {
    position: fixed;
    top: 50%;
    background: transparent;
    font-size: 36px;
    cursor: pointer;
    color: #ffffff;
    z-index: 1;
    border-style: none;
}

.artwork-arrow-button.left {
    left: 15px;
}

.artwork-arrow-button.right {
    right: 15px;
}

.modal-title {
    font-size: 1.5em;
    text-align: center;
    margin: 10px 0;
}

.modal-description {
    margin-top: 5px;
    text-align: center;
}

/* Redbubble Gallery Section */
.redbubble-gallery {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
    padding: 10px 0;
}

.redbubble-image-wrapper {
    flex: 1;
    max-width: calc(33.33% - 10px);
    /* Adjust for up to 3 images */
    display: inline-block;
}

.redbubble-image {
    width: 100%;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.redbubble-image:hover {
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .redbubble-image-wrapper {
        max-width: 100%;
    }
}