.games-container {
    margin: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.game-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 50px;
    border: 1px solid #ddd;
    border-radius: 15px;
    transition: box-shadow 0.3s ease;
}

.game-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.game-thumbnail {
    width: 300px;
    height: 300px;
    object-fit: contain;
    background-color: black;
    margin-right: 50px;
    border-radius: 15px;
}

.game-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.game-button-container {
    display: flex;
    justify-content: flex-end;
}

.play-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 15px 0;
    text-align: center;
    width: 150px;
    background-color: transparent;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: 'Neuro';
}

.play-button:hover {
    background-color: #ffffff40;
}



@media (max-width: 870px) {
    .game-item {
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }

    .game-item h3 {
        text-align: center;
    }

    .game-thumbnail {
        width: 100%;
        height: auto;
        max-height: 190px;
        margin-right: 0;
        margin-bottom: 0;
    }

    .game-button-container {
        justify-content: center;
        width: 100%;
    }

    .games-container {
        margin: 30px 0;
    }
}

@media (max-width: 450px) {
    .game-item {
        border: none;
        padding: 8px;
    }
}