@font-face {
  font-family: 'Neuro';
  src: url('./fonts/neuropolitical.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofa';
  src: url('./fonts/sofachrome.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nulshock';
  src: url('./fonts/nulshock.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('./fonts/Exo2-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
}

body {
  font-family: 'Exo';
  color: white;
  padding: 0;
  background-color: #2359a4;
  line-height: 1.4em;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
}

main {
  box-sizing: border-box;
  border-color: white;
  padding: 50px;
  background-color: #3243a5df;
  border-radius: 15px;
  margin: 15px;
  min-height: 75vh;
}

nav {
  background-color: #282c34;
  padding: 10px;
  font-family: 'Neuro';
}

nav ul {
  list-style: none;
  padding: 0;
  text-align: center;
  /* Centers the navigation items */
}

nav ul li {
  display: inline;
  margin-right: 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

h1 {
  color: #282c34;
  text-align: center;
}

a {
  text-decoration: none;
  color: #8ccaf3;
  font-weight: bold;
  margin-top: 5px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h2 {
  font-family: 'Nulshock', sans-serif;
  font-size: x-large;
  margin-bottom: 25px;
}

h3,
h4,
.section-header {
  font-family: 'Nulshock', sans-serif;
}

h3 {
  padding: 10px 0;
  font-size: large;
}

h4 {
  padding: 10px 0;
  font-size: medium;
}



html {
  overflow-y: scroll;
}

.section-header {
  cursor: pointer;
  background-color: #e0e0e010;
  padding: 10px;
  margin: 15px 0;
  border-radius: 15px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
}

.section-header:hover {
  background-color: #e0e0e030;
}

.item-title {
  margin: 5px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 10px;
  margin-left: 13px;
}

.arrow.down {
  border-bottom: 5px solid white;
}

.arrow.up {
  border-top: 5px solid white;
}

.section-title {
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.section-description {
  margin: 10px;
  font-style: italic;
  color: #bbceed;
  text-align: center;
}


/* Merged classes from other CSS files */

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.grid div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(0deg,
      #6371c9ee,
      #405e85cc 2px,
      #000000 2px,
      #000000 4px);
  /* background: rgba(0, 0, 0, 0.7); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
}

.modal-description {
  /* background-color: #24384d30; */
  padding: 10px 15px;
  margin-top: 15px;
  text-align: justify;
}

.modal-title {
  text-align: center;
  font-family: 'Nulshock', sans-serif;
  font-size: x-large;
  margin-bottom: 19px;
}

.modal-content {
  background: rgb(56, 73, 168);
  padding: 15px;
  margin: auto;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  position: relative;
  max-height: 90vh;
  overflow: auto;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: white;
  font-family: 'Neuro';
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #666;
  font-size: 14px;
  font-weight: bold;
}

.fixed-component {
  position: fixed;
  z-index: 1000;
}

/* Reset padding and margin */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url('img/bg_full.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  overflow-x: hidden;
}

/* For screens 1920px wide or smaller */
@media screen and (max-width: 1920px) {
  body {
    background-image: url('img/bg_small.png');
  }
}

@media (max-width: 930px) {
  h2 {
    text-align: center;
  }

  h3 {
    padding: 7px;
  }

  main {
    padding: 30px;
  }
}