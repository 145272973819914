.artwork-image-thumbnail {
    cursor: pointer;
    border-radius: 15px;
    /* overflow: hidden; */
    width: auto;
    height: 240px;
    max-width: 100%;
    object-fit: contain;
    margin: 3px;
}

.artwork-image-thumbnail img {
    width: auto;
    height: 80%;
    border-radius: 15px;
}

.art-item-title {
    max-width: 200px;
}

@media (max-width: 760px) {
    .artwork-section-description {
        display: none;
    }
}