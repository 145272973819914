ul {
    margin: 0;
    padding: 0;
}


.post-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.post-date {
    font-size: 0.9em;
    color: rgb(127, 143, 228);
}

.post-link {
    text-decoration: none;
}

.post-link:hover {
    text-decoration: underline;
}

@media (max-width:930px) {
    .post-date {
        display: none;
    }

    .post-footer {
        justify-content: end;
    }
}