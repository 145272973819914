/* ImageGallery.css */
.image-gallery {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin: 30px 0;
}

.gallery-image {
    object-fit: cover;
    border-radius: 15px;
    cursor: default;
    width: 100%;
    max-height: 180px;
}