.footer {
    background-color: #253593df;
    color: WHITE;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}