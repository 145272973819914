.thumbnail-image,
.placeholder {
    width: 270px;
    height: 152px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #ccc;
}

img {
    cursor: pointer;
}

.player-container h3 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.2em;
}

.player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.video-thumbnail {
    cursor: pointer;
}

.animation-item-title {
    max-width: 190px;
}

@media (max-width: 760px) {
    .section-description {
        text-align: center;
        /* display: none; */
    }
}