.container {
    display: flex;
    gap: 15px;
    justify-content: center;
    padding: 10px 0;
}

.link {
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: transparent;
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.label {
    margin-left: 8px;
}

.link:hover {
    background-color: #1DA1F2;
    transform: scale(1.05);
}