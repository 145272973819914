.album-image,
.placeholder {
    width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 8px;
    background-color: transparent;
}

img a {
    cursor: pointer;
}


.player-container h3 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.2em;
}

.music-item-title {
    max-width: 150px;
}

.soundcloud-players {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 48%;
}

.player-container iframe {
    border-radius: 15px;
}

.music-page-image {
    height: 230px;
    width: 100%;
    margin: 30px 0;
    object-fit: cover;
    object-position: 50% 60%;
    border-radius: 15px;
    cursor: default;
}

@media (max-width: 760px) {
    .soundcloud-players {
        flex-direction: column;
        align-items: center;
    }

    .player-container iframe {
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        flex-shrink: 0;
    }
}