.hire-me-container {
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-input,
.form-textarea {
    margin: 10px 0;
    padding: 30px;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 16px;
    background-color: transparent;
    color: white;
    font-family: 'Exo';
}

.form-textarea::placeholder {
    color: #a0adf3;
}

.form-textarea {
    height: 160px;
    padding: 10px;
    resize: none;
}

.form-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 16px;
}

.form-input::placeholder {
    color: white;
}

.submit-button {
    margin-top: 30px;
    padding: 10px;
    border: solid;
    border-radius: 15px;
    border-width: 1px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.form-select {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 16px;
    align-content: center;
    background-color: transparent;
    color: white;
}

.form-select option {
    background-color: #253593;
}

.collaboration-text {
    margin-bottom: 30px;
    font-size: medium;
    text-align: justify;
}